<template>
  <v-main>
    <v-container>
      <h3 class="mb-5 mt-5">Create Post</h3>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <v-btn @click="goBackToPostList" class="mb-5">Back to Post List</v-btn>
      <div v-if="loading">Loading...</div>
      <v-text-field v-model="post.title" label="Add Title" outlined>
      </v-text-field>
      <div class="mt-5 mb-5">
        <fileInput
          v-model="post.feature_img_url"
          label="Upload Feature Image"
          @get-url="setFeatureImageUrl($event)"
        />
      </div>
      <v-text-field
        v-model="post.slug"
        label="Slug"
        hint="small letter with dash e.g modern-contraceptive-method "
        outlined
      ></v-text-field>
      <v-radio-group v-model="post.language_id" row mandatory>
        <template v-slot:label>
          <div>Language</div>
        </template>
        <v-radio
          v-for="(l, idx) in postLanguage"
          :key="idx"
          :label="`${l.lang_name}`"
          :value="l.id"
          :off-icon="post.language_id == l.id ? '$radioOn' : '$radioOff'"
        ></v-radio>
      </v-radio-group>
      <v-radio-group v-model="post.post_type_id" row mandatory>
        <template v-slot:label>
          <div>Post Type</div>
        </template>
        <v-radio
          v-for="(n, idx) in postType"
          :key="idx"
          :label="`${n.name}`"
          :value="n.id"
          :off-icon="post.post_type_id == n.id ? '$radioOn' : '$radioOff'"
        ></v-radio>
      </v-radio-group>
      <PostCategory
        v-model="post.post_category_id"
        :value="post.post_category_id"
        :language_id="post.language_id"
        :post_category_id="post.post_category_id"
        ref="postCategoryList"
      ></PostCategory>

      <ckeditor v-model="post.body"
      @get-text="setContents($event)"
      :textContent="post.body"
      ></ckeditor>

      <v-btn @click="goBackToPostList">Back To Post List</v-btn>|<v-btn
        @click="createPost"
        >Create</v-btn
      >
    </v-container>
  </v-main>
</template>

<script>
import { CREATE_POST, GET_REF_DATA } from "../store/actions.type";
import PostCategory from "@/components/PostCategory.vue";
import ckeditor from "@/components/CkEditorComponent.vue";
import fileInput from "@/components/FileUploadComponent.vue";
export default {
  name: "CreatePost",
  components: {
    PostCategory,
    ckeditor,
    fileInput,
  },
  data() {
    return {
      radioGroup: 1,
      postCategory: [],
      postLanguage: [],
      postType: [],
      post: {
        language_id: "0",
        post_type_id: "0",
        post_category_id: "1",
        title: "",
        description: "",
        body: "",
        slug: "",
        feature_img_url: "https://media.milady-rh.com/upload/files/milady.png",
        author: "CHAI Myanmar",
        active: 1,
        sort: "",
        createdby: "CHAI Myanmar",
        createddate: "",
        updatedby: "",
        updateddate: "",
      },
      loading: true,
      errored: false,
    };
  },
  computed: {},
  mounted() {},
  created() {
    this.$store
      .dispatch(GET_REF_DATA)
      .then((data) => {
        this.loading = false;
        this.postLanguage = data.data.language;
        this.postCategory = data.data.post_category;
        console.log(this.postCategory);
        this.postType = data.data.post_type;
      })
      .catch((response) => {
        console.log(response);
        this.loading = false;
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
  methods: {
    setContents(event) {
      this.post.body = event;
    },
    setFeatureImageUrl(event){
      console.log(event);
      this.post.feature_img_url = event;
    },
    onChildUpdate(newValue) {
      this.value = newValue;
      console.log(this.value);
    },
    goBackToPostList() {
      this.$router.push({
        name: "PostList",
      });
    },
    resetPost() {
      this.post = {
        language_id: "0",
        post_type_id: "0",
        post_category_id: "1",
        title: "",
        description: "",
        body: "",
        slug: "",
        feature_img_url: "https://media.milady-rh.com/upload/files/milady.png",
        author: "CHAI Myanmar",
        active: 1,
        sort: "",
        createdby: "CHAI Myanmar",
        createddate: "",
        updatedby: "",
        updateddate: "",
      };
    },
    createPost() {
      this.post.post_category_id = localStorage.getItem("selectedCategoryID");
      console.log(this.post);
      if (this.post.title) {
        this.$store
          .dispatch(CREATE_POST, { postData: this.post })
          .then(() => {
            this.loading = false;
            this.$swal.fire({
                    title: "Post",
                    text: "Successfully created!",
                    icon: "success",
                    position: "center",
                    showConfirmButton: false,
                    timer: 1500,
                  });
            this.resetPost();
            this.goBackToPostList();
          })
          .catch((response) => {
            this.$swal.fire({
                    title: "Post",
                    text: "Failed to create!",
                    icon: "danger",
                    position: "center",
                    showConfirmButton: false,
                    timer: 1500,
                  });
            console.log(response);
            this.loading = false;
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      }
      localStorage.removeItem("selectedCategoryID");
    },
  },
};
</script>
